.App {
  text-align: left;
  
  
}

.container {
  margin: 0% 0% 0% 0%;
  max-width: 2000px;
  
}

input {
  font-size: 14px !important;
}
.sm {
  font-size: 14px;
  cursor: pointer;
}
.sm2 {
  font-size: 14px;
  
}

.bg-aatrac {
  opacity: .78;
  background-color: #0063a0 !important;
  margin-bottom: 6px;
}

.bg-aatrac-gris {
  /*opacity: .28;*/
  background-color: #fafafa !important;
  margin-bottom: 6px;
}

.btn-aatrac {
  background-color: #0063a0 !important;
  color: aliceblue;
}

body {
  font-family: Roboto-Regular,sans-serif;
    font-weight: lighter;
  background-color: #fefefe;
}

.bg-aatrac-flat {
  opacity: .78;
  background-color: #0063a0 !important;
  height: 1px;
  margin-bottom: 6px;
}

.div-error {
  font-size: 14px;
  height: 24px !important;
  vertical-align: middle !important;
  padding-top: 2px !important;
}

.botonera {
  
    overflow: hidden;
    background-color: #fefefe;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    margin-top: 96px; /* Add a top margin to avoid content overlay */
    z-index: 1;
    
}